// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";
button.btn.btn-success,
.btn.btn-danger {
  border: none;
}
.white-background {
  background: #fff !important;
  // overflow: scroll !important;
}
.white-backgroundx {
  background: #fff !important;
  height: auto !important;
}

.blue.card-body {
  background: #321fdb;
}
header.c-header.c-header-light.c-header-fixed.c-header-with-subheader {
  top: 0px !important;
}
::-webkit-scrollbar {
  width: 10px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #041d32; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #041d32; /* creates padding around scroll thumb */
}

// .white-background {
//   min-height: 315px !important;
// }
body {
  margin-top: 0px !important;
}
.containerx {
  padding: 0 !important;
}

.btnx {
  background-color: #3c4b64;
  color: white;
  border: "none";
}
.btn-success:focus,
.btn-success.focus {
  background-color: #2eb85c !important;
  box-shadow: none !important;
}
.btn-danger:focus,
.btn-danger.focus {
  background-color: #e55353 !important;
  box-shadow: none !important;
}
.theadBlue {
  color: white !important;
  background-color: #39f !important;
}
.text-value-lg {
  font-size: 35px !important;
}

.table tr {
  cursor: revert !important;
}
.table tr:hover {
  cursor: revert !important;
  background-color: transparent !important;
}
.paymentset img {
  width: 25px;
}

span.mr-3.ml-1.fa-lg.paymentset {
  margin-right: 8px!important;
}


ul.c-sidebar-nav.h-100.ps.ps--active-y li.c-sidebar-nav-item svg {
  color: #858f9e !important;
}
.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #858f9e !important;
}