.red_circle {
  background: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: relative;
  bottom: 12px;
  right: 18px;
  color: #ffff;
}

.notificationPanel {
  width: 505px;
  max-height: 355px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.notificationPanel .row {
  border-bottom: 1px solid #d2d2d2;
}
@media screen and (max-width: 600px) {
  .notificationPanel {
    width: 335px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 535px;
  }
}

/* .rowStyle:hover {
  background-color: rgb(235, 237, 239);
  cursor: pointer;
} */

.row.rowEffect:hover {
  background: #ebedef;
  cursor: pointer;
}

.Markread {
  color: rgb(109, 109, 207);
  float: right;
  margin-right: 10px;
}

.Markread:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
section#notifaction_design {
  width: 100%;
  max-width: 450px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 4px 5px 8px 9px #dcdcdc54;
  margin: 0 auto;
  padding: 23px;
}

.profile img {
  width: 40px;
  border-radius: 50%;
  height: 40px;
}

.notification-first {
  display: flex;
  justify-content: space-between;
}

.notification-first h4 {
  color: #47aef3;
  font-size: 13px;
  margin-top: 10px;
}

.notification-first h2 {
  font-size: 18px;
  font-weight: 600;
}

.profile {
  float: left;
}
section#notification_design {
  padding: 13px;
  border-radius: 15px;
  box-shadow: 4px 5px 8px 9px #4b4a4a54;
  background: #ffff;
}
.dropdown-menu.show {
  background: transparent;
  box-shadow: none;
  border: none;
}
.profile_name {
  margin-left: 60px;
}
span.color_blue {
  color: #47aef3;
}
section#notification_design {
  background-color: #ffffff;
  padding: 13px;
}
.mumber_name {
  margin-top: 6px;
}
section#notification_design hr {
  margin: 5px 0px;
}
.profile_name h6 {
  font-size: 14px;
  color: gray;
  word-break: break-all;
}

section#notifaction_design p {
  color: gray;
}

.profile_name h3 {
  font-size: 16px;
  font-weight: 700;
}
.notificationPanel h3 {
  font-size: 17px;
}
